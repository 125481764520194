<template>
  <v-row justify="center">
    <v-dialog v-if="!DisableDialog"
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Bedrijf Aanpassen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col>
                <v-autocomplete
                    auto-select-first
                    @change="CompanyInfo"
                    :items="CompanyList"
                    item-text="name"
                    item-value="name"
                    label="Selecteer een een bedrijf"
                />
                <v-row style="">
                  <v-col
                    cols="12"
                    sm="6"
                    md="7"
                  >
                    <v-text-field
                    label="Bedrijfsnaam*"
                    v-model="CompanyName"
                    :rules="[v => !!v || 'Bedrijfsnaam is verplicht']"
                    required
                    ></v-text-field>

                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="5"
                  >
                  <v-text-field
                    label="Plaatnaam*"
                    v-model="CityName"
                    persistent-hint
                    required
                  ></v-text-field>
                  </v-col>
                </v-row>
               
                
            </v-col>
            <v-row style="margin-left:0vh; margin-right:0vh;">
              <v-col
                cols="12"
                sm="6"
                md="7"
                >
                <v-text-field
                  label="address"
                  v-model="Address"
                  :rules="[
                        v => !!v || 'Address is verplicht',
                        ]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="5"
              >
                <v-text-field
                  label="postcode*"
                  v-model="PostalCode"
                  :rules="PostalCodeRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>Met teken (*) zijn verplicht</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="DeleteButton"
            color="red darken-1"
            text
            @click="DisableDialog = true"
          >
            verwijderen
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="CloseDialog('ShowEditCompany')"
          >
            terug
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="EditCompany()"
          >
            Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="DisableDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Weet je zeker?
        </v-card-title>

        <v-card-text>
          Als je een bedrijf verwijderd, is het niet meer te herstellen.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            @click="ChangeStatus(false)"
          >
            Annuleren
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="ChangeStatus(true)"
          >
            Verwijderen
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
  </v-row>
</template>

<script>
  import axios from 'axios';

  export default {
    data: () => ({
      CompanyList: [],
      DisableDialog: false,
      DeleteButton: false,
      dialog: true,
      CompanyName:null,
      Company_id:null,
      Address:null,
      CityName:null,
      PostalCode:null,
      PostalCodeRules: [
        v => !!v || "Postcode is verplicht",
      ]
    }),
    mounted() {
      this.GetCompanyList();
    },
    methods: {
      CloseDialog(dialog) {
        this.$emit('CloseDialog', dialog)
      },
      CompanyInfo(data) {
        for(let i=0; i < this.CompanyList.length; i++) {
          if(this.CompanyList[i].name == data) {
            this.CompanyName = this.CompanyList[i].name;
            this.CityName = this.CompanyList[i].city_name;
            this.Address = this.CompanyList[i].address;
            this.PostalCode = this.CompanyList[i].postalcode;
            this.Company_id = this.CompanyList[i].id;
          }
        }

        this.DeleteButton = true;
      },
      GetCompanyList() {
        axios.post(this.$api+'/branch/get', {}, { headers: { Authorization: `Bearer `+ localStorage.token }})
          .then(response => {
            this.CompanyList = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      },
      EditCompany() {
        if(this.CompanyName !== null && this.Address !== null && this.PostalCode !== null) {
            axios.post(this.$api+'/branch/edit', {
            id: this.Company_id,
            name: this.CompanyName,
            address: this.Address,
            postalcode: this.PostalCode,
            city_name: this.CityName
          }, 
          { headers: { Authorization: `Bearer `+ localStorage.token }})
          .then(succes => {
              this.$emit('dialog', succes.data.body, 5000);
              this.CloseDialog('ShowEditCompany')
          })
          .catch(error => {
              if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
              this.$emit('dialog', error.response.data.body, 5000);
          })
        }
      },
      ChangeStatus(bool) {
        if(bool) {
          axios.post(this.$api+'/branch/edit', {
            id: this.Company_id,
            disabled: bool
          }, 
          { headers: { Authorization: `Bearer `+ localStorage.token }})
          .then(succes => {
              this.$emit('dialog', succes.data.body, 5000);
              setTimeout(() => {  this.CloseDialog('ShowEditCompany') }, 2000);
          })
          .catch(error => {
              if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
              this.$emit('dialog', error.response.data.body, 5000);
          })
        } else {
          this.CloseDialog('ShowEditCompany')
        }
      }
    }
  }
</script>