<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Bedrijf Aanmaken</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row style="margin-left:0vh; margin-right:0vh;">
                <v-col cols="12" sm="6" md="7">
                  <v-text-field
                  label="Bedrijfsnaam*"
                  v-model="CompanyName"
                  :rules="[v => !!v || 'Bedrijfsnaam is verplicht']"
                  required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                  <v-text-field
                  label="Plaatsnaam*"
                  v-model="CityName"
                  :rules="PostalCodeRules"
                  persistent-hint
                  required
                ></v-text-field>
                </v-col>
            </v-row>
            <v-row style="margin-left:0vh; margin-right:0vh;">
              <v-col cols="12" sm="6" md="7">
                <v-text-field
                  label="address"
                  v-model="Address"
                  :rules="[
                    v => !!v || 'Address is verplicht',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="5"
              >
                <v-text-field
                  label="postcode*"
                  v-model="PostalCode"
                  :rules="PostalCodeRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>Met teken (*) zijn verplicht</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="CloseDialog('ShowCreateCompany')"
          >
            terug
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="CreateCompany()"
          >
            Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from 'axios';

  export default {
    data: () => ({
      dialog: true,
      CompanyName: null,
      CityName: null,
      Address: null,
      PostalCode: null,
      PostalCodeRules: [
        v => !!v || "Postcode is verplicht",
      ]
    }),
    methods: {
      CloseDialog(dialog) {
        this.$emit('CloseDialog', dialog)
      },
      CreateCompany() {
          if(this.CompanyName !== null && this.Address !== null && this.PostalCode !== null) {
              axios.post(this.$api+'/branch/create', {
              name: this.CompanyName,
              address: this.Address,
              postalcode: this.PostalCode,
              city_name: this.CityName
            }, 
            { headers: { Authorization: `Bearer `+ localStorage.token }})
            .then(succes => {
                this.$emit('dialog', succes.data.body, 5000);
                this.CloseDialog('ShowCreateCompany')
            })
            .catch(error => {
                if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                this.$emit('dialog', error.response.data.body, 5000);
            })
          }
      }
    }
  }
</script>