<template> 
    <div class="main">
         <v-expansion-panels focusable>
            <v-expansion-panel
            v-for="(product,i) in products"
            :key="i"
            >
            <v-expansion-panel-header expand-icon="">
                <div class="panel_header_name">{{product.short}}</div>
                <div class="panel_header_input">
                    <v-row>
                        <v-col class="pa-0 min-button">
                            <v-btn
                            color="primary"
                            elevation="2"
                            icon
                            @click.native.stop
                            @click="ChangeCount(i, '-')">
                                <v-icon dark>mdi-minus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="pa-0 text-input">   
                            <v-text-field
                                @click.native.stop
                                placeholder="0"
                                outlined
                                dense
                                type="number"
                                style="height: 5vh"
                                v-model="ItemCount[i].Number"
                                v-on:change="ChangeCount(i, null)"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pa-0 plus-button">  
                            <v-btn
                            @click="ChangeCount(i, '+')"
                            @click.native.stop
                            color="primary"
                            elevation="2"
                            icon>
                                <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <b>Volledige naam:</b> {{product.title}} <br/>
                <b>Barcode:</b> {{product.barcode}} <br/>
                <b>Aantal:</b> {{product.amount}} <br/>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data () {
            return {
                items: 0,
                products: null,
                ItemCount: [],
            }
        },
        props: [
            'CompanyId',
            'SaveProducts'
        ],
        methods: {
            ChangeCount(numb, method) {
                var Number = this.ItemCount[numb].Number
                if(isNaN(Number)) {this.ItemCount[numb].Number = 0}
                else if(Number === '') {this.ItemCount[numb].Number = 0}
                else if(method == '+') {this.ItemCount[numb].Number = Number+1}
                else if(method == '-') {this.ItemCount[numb].Number = Number-1}
                localStorage.setItem('product_count_'+this.CompanyId, JSON.stringify(this.ItemCount))
                for(var i = 0; i < this.ItemCount.length; i++) {
                    if(this.ItemCount[i].Number !== 0) {
                        this.$emit('update-count', true); // add dialog that product will be added if submit
                    }
                }
            },
            GetProducts() {
            axios.post(this.$api+'/barcode/get/'+this.CompanyId, {},
            {
                headers: {
                    Authorization: `Bearer `+ localStorage.token
                }
            })
            .then(response => {
                this.products = response.data;
                this.items = response.data.length;

                var items = [];
                let itemCountLocal = [];
                if(localStorage.getItem('product_count_'+this.CompanyId) !== null) {
                    itemCountLocal = JSON.parse(localStorage.getItem('product_count_'+this.CompanyId));
                }

                if(itemCountLocal.length === response.data.length) { // check if length is
                    for (let i = 0; i < response.data.length; i++) {
                        items.push(itemCountLocal[i]);
                    }
                    this.$emit('update-count', true); // add dialog that product will be added if submit
                } else {
                    for (let i = 0; i < response.data.length; i++) { items.push({Number:0}); }
                }

                this.ItemCount = items;
            })
            .catch(error => {
                if(error.response.status == 401) { location.reload(); } // redirect to login
                console.error(error);
            })
            },
            UpdateProducts() {
                var items = [];
                for (let i = 0; i < this.products.length; i++) { 
                    if(this.ItemCount[i].Number !== 0) {
                        items.push({
                            barcode:this.products[i].barcode,
                            branch:this.CompanyId,
                            added: (parseInt(this.products[i].amount) + parseInt(this.ItemCount[i].Number))
                        });
                    }
                }
                
                axios.post(this.$api+'/product/create', items,
                {
                    headers: {
                        Authorization: `Bearer `+ localStorage.token
                    }
                })
                .then(
                    localStorage.removeItem('product_count_'+this.CompanyId),
                    this.$emit('ReturnDialog', 'Producten Lijst opgeslagen!', 10000),
                    this.GetProducts()
                )
                .catch(error => {
                    if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                    console.error(error);
                })
            },
            CanSave() {
                if(this.SaveProducts) {
                    this.UpdateProducts();
                } else {
                    setTimeout(() => {  this.CanSave();}, 3000);
                }
            }
        },
        mounted() {
            this.GetProducts();
            this.CanSave();
        }
    }
</script>

<style scoped>
    .main {
        height: 57.5vh; 
        overflow: hidden; 
        overflow: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .panel_header_name {
        font-weight: bold;
        margin-right: 55px;
        max-width: 50%;
    }

    @media only screen and (min-width: 450px) {
        .panel_header_name {
           margin-right: 30px;
        }
        .text-input {
            min-width: 75px;
        }
    }

     @media only screen and (max-width: 400px) {
        .panel_header_name {
            margin-right: 30px;
        }
     }

    .panel_header_input {
        width:10px;
    }
    .min-button {
        max-width:45px;
    }
    .plus-button {
        max-width:45px;
        margin-left: 7px;
    }
    .text-input {
        min-width: 50px;
    }
</style>
