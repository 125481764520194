<template>
  <v-card>
    <v-autocomplete
      auto-select-first
      @change="GetProductFilled"
      v-model="selectedBranch"
      :loading="loading"
      :items="companylist"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      return-object
      cache-items
      flat
      hide-no-data
      hide-details
      solo-inverted
      style="max-width:350px"
    ></v-autocomplete>

         <v-data-table
            :headers="headers"
            :items="products"
            item-key="id"
            group-by="datum"
            class="DataTable HideScrollbar"
          ></v-data-table>
  </v-card>
</template>

<script>
  import axios from 'axios';

  export default {
    data () {
      return {
        CompanyId: 1,
        headers: [
          {
            text: 'titel',
            align: 'start',
            value: 'name',
            groupable: true,
          },
          { text: 'vuller', value: 'vuller', align: 'right' },
          { text: 'barcode', value: 'barcode', align: 'right' },
          { text: 'aantal', value: 'aantal', align: 'right' },
        ],
        products: [
          // Get from api.
        ],
        companylist: [],
        selectedBranch: {id: null, name: null},
        search: null,
        loading: false
      }
    },
    methods: {
      GetProductFilled() {
        axios.post(this.$api+'/product/history/'+this.selectedBranch.id, {},
            {
                headers: {
                    Authorization: `Bearer `+ localStorage.token
                }
            })
        .then(succes => {
          let array = [];
          for (let i = 0; i < succes.data.length; ++i) {
            array.push({
              id: succes.data[i].id,
              name: succes.data[i].name,
              barcode: succes.data[i].barcode,
              vuller: succes.data[i].filler,
              datum: succes.data[i].date,
              aantal: succes.data[i].added
            });
          }
          this.products = array;
        })
        .catch(error => {
          if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
          console.error(error);
        })
      },
      GetCompanyList() {
          axios.post(this.$api+'/branch/get', {}, {
            headers: {
                Authorization: `Bearer `+ localStorage.token
            }
          })
          .then(succes => {
            for (let i = 0; i < succes.data.length; ++i) {
              this.companylist.push({
                id: succes.data[i].id,
                name: succes.data[i].name
              },);
            }
          })
          .catch(error => {
            if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
            console.error(error);
          })
      }
    },
    mounted() {
      this.GetProductFilled();
      this.GetCompanyList();
    },
    watch: {
        search (val) {
          if(val !== null) {
            val && val !== this.selectedBranch.name;
          }
        },
      },
    
  }
</script>

<style scoped>
  .DataTable {
    height:80vh;
      overflow: scroll;
  }

  .HideScrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .HideScrollbar::-webkit-scrollbar {
    display:none;
  }
    
</style>