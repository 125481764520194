<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">product Aanpassen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
              <v-form v-model="valid">
                <v-container>
                    <v-autocomplete
                      @change="TitleBarCode"
                      auto-select-first
                      v-model="SelectedBarcode"
                      :items="barcodes"
                      item-text="article_numb"
                      item-value="barcode"
                      label="Selecteer een artikel nummer"
                    />

                    <v-text-field
                        v-model="title"
                        :rules="Rules"
                        label="Product Naam"
                        required
                    ></v-text-field>

                     <v-text-field
                        v-model="title_short"
                        :rules="Rules"
                        label="Product Naam (Kort)"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="barcode"
                        :rules="Rules"
                        label="barcode"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="ArticleNumb"
                        :rules="Rules"
                        label="Artikelnummer"
                        required
                    ></v-text-field>

                    <v-select
                    v-model="select"
                    hint="Selecteer een status"
                    :items="items"
                    persistent-hint
                    return-object
                    single-line
                    ></v-select>
                </v-container>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="CloseDialog('ShowEditBarcode')"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="EditBarCode"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
    import axios from 'axios';

    export default {
        data: () => ({
            SelectedBranch: {name:null, barcode:null, article_number:null, status:null},
            SelectedBarcode: null,
            select: 'Active',
            items: [
                'Active','Disabled','Hidden'
            ],
            dialog: true,
            valid: false,
            barcode: '',
            id: '',
            title: '',
            title_short:'',
            ArticleNumb:'',
            barcodes: [],
            Rules: [
                v => !!v || 'Dit is verplicht',
            ],
        }),
        methods: {
            CloseDialog(dialog) {
              this.$emit('CloseDialog', dialog)
            },
            GetBarCodes() {
              axios.post(this.$api+'/barcode/get',{},
              {
                headers: {
                    Authorization: `Bearer `+ localStorage.token
                }
              })
              .then(succes => {
                for(var i=0; i < succes.data.length; i++) {
                  this.barcodes.push({
                    name: succes.data[i].title,
                    name_short: succes.data[i].short,
                    barcode: succes.data[i].barcode,
                    article_numb: succes.data[i].article_number,
                    status: succes.data[i].status,
                    id: succes.data[i].id
                  },)
                }
              })
              .catch(error => {
                console.error(error);
              })
            },
            TitleBarCode() {
              for(var i=0; i < this.barcodes.length; i++) {
                if(this.barcodes[i].barcode == this.SelectedBarcode) {
                  this.title = this.barcodes[i].name;
                  this.title_short = this.barcodes[i].name_short;
                  this.ArticleNumb = this.barcodes[i].article_numb;
                  this.barcode = this.barcodes[i].barcode;
                  this.id = this.barcodes[i].id;
                  
                  this.SelectedBranch = [{
                    name: this.barcodes[i].name, 
                    name_short: this.barcodes[i].name_short,
                    barcode: this.barcodes[i].barcode,
                    art_num: this.barcodes[i].article_number,
                    status: this.barcodes[i].status,
                    id: this.barcodes[i].id
                  }]
                }
              }
            },
            EditBarCode() {
              if(this.valid && this.SelectedBranch.barcode !== null) {
                axios.post(this.$api+'/barcode/edit',{
                  id: this.id,
                  barcode:this.barcode,
                  article_number: this.ArticleNumb,
                  status: this.select,
                  short: this.title_short,
                  title:this.title
                },
                {
                  headers: {
                      Authorization: `Bearer `+ localStorage.token
                  }
                })
                .then(succes => {
                  this.$emit('dialog', succes.data.body, 5000);
                  this.CloseDialog('ShowEditBarcode');
                })
                .catch(error => {
                  this.$emit('dialog', error.response.data.body, 5000);
                  if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                })
              } else {
                this.$emit('dialog', 'Geef barcode & Product naam a.u.b', 5000);
              }
            }
        },
        mounted() {
          this.GetBarCodes();
        }
    }
</script>