<template>
    <div id="main_dashboard" class="MainFlexBox">
        <side-bar id="MenuBar" @dialog="ReturnDialog" @redirect="Redirect" class="FlexMenuBar"/>
        <history-list class="FlexListContent"/>
        <barcode-create style="order:100;" v-if="ShowCreateBarcode"/>
        <download-excel id="excel_download" class='FlexListContent2'/>
    </div>
</template>

<script>
    import SideBar from '/src/components/AdminPanel/A-SideBar.vue';
    import HistoryList from '/src/components/AdminPanel/A-HistoryList.vue';
    import BarCodeCreate from '/src/components/AdminPanel/A-Barcode-Create.vue';
    import DownloadExcel from '/src/components/AdminPanel/A-Download_Excel.vue';

    export default{
        data() {
            return {
                ShowCreateBarcode: false,
            }
            
        },
        async mounted () {
            const vm = this;
            await vm.Resize(vm);
            window.addEventListener('resize', async function() {
                await vm.Resize(vm);
            });

        },
        components: {
            'side-bar':SideBar,
            'history-list':HistoryList,
            'barcode-create':BarCodeCreate,
            'download-excel':DownloadExcel
        },
        methods: {
            async DisplaySize() {
              return new Promise(resolve => {
                resolve({
                    main: document.getElementById('main_dashboard').offsetWidth,
                    menubar: document.getElementById('MenuBar').offsetWidth
                })
              })  
            },
            async Resize(vm) {
                const display = await vm.DisplaySize()
                document.getElementById('excel_download').style.marginLeft = (display.menubar + 10) + 'px';
            },
            Redirect(data) {
                this.$emit('redirect', data) // send to parent.
            },
            ReturnDialog(text, timer) {
                this.$emit('dialog', text, timer);
            }
        }
    }
</script>

<style scoped>
    .MainFlexBox {
        display: flex;
        height: 93%;
    }

    .FlexMenuBar {
        width:250px;
    }

    .FlexListContent2 {
        margin-top:1vh;
        width: 325px;
        order:3;
    }

      @media screen and (max-width: 600px){
        .FlexListContent2 {
            display: none;
        }
      }

    

    .FlexListContent {
        display: none;
        order:2;
        width:25%;
        margin-top:5vh;
        margin-bottom:5vh;
    }
</style>