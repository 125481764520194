

<template>
  <div>
    <v-btn 
        icon
        color="black"
        x-large
        @click.stop="dialog = true"

        >
        <v-icon>mdi-cloud-check</v-icon>
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Bedrijf afgerond?
        </v-card-title>

        <v-card-text>
          <p>
            Selecteer "Gevuld" om aan te geven dat je de producten hebt aangevuld.
          </p>
          <p>
            Selecteer "Geteld" om aan te geven dat je het producten heb inventariseert.
          </p>
          <p>
            Er wordt vervolgens een mail gestuurd naar de administratie.
          </p>
          <div v-if="ItemsInList">
            Alles wat in de product lijst staat wordt dan ook opgeslagen.
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Nee
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="SendMail('filled')"
          >
            Gevuld
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="SendMail('counted')"
          >
            Geteld
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

  export default {
    data () {
      return {
        dialog: false,
      }
    },
    props: [
            'CompanyId',
            'ItemsInList'
    ],
    methods: {
        SendMail(label) {
            this.$emit('save-products-list');
            setTimeout(() => {
              // check if there are products.
              axios.post(this.$api+'/product/mail', {
                  "company_id": this.CompanyId,
                  "user_id": localStorage.id,
                  "date": this.CurrentDate(),
                  "label": label
              },
              {
                  headers: {
                      Authorization: `Bearer `+ localStorage.token
                  }
              })
              .then(succes => {
                  this.$emit('ReturnDialog', succes.data.body, 5000);
                  this.dialog = false;
                  localStorage.removeItem('history_list_'+this.CompanyId);
                  setTimeout(() => { this.$emit('redirect', 'company-list'); }, 2000); // wait for 2 seconds
              })
              .catch(error => {
                  this.$emit('ReturnDialog', error.response.data.body.message, 5000);
                  console.error("Options: " + error.response.data.body.Options);
                  this.dialog = false;

              })
            }, 5000);
        },
        CurrentDate() {
            let yourDate = new Date();
            yourDate.toISOString().split('T')[0];
            const offset = yourDate.getTimezoneOffset();
            yourDate = new Date(yourDate.getTime() - (offset*60*1000));
            return yourDate.toISOString().split('T')[0];
        }
    },
    mounted() {
        this.CurrentDate();
    }
  }
</script>