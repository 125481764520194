<template>
    <div>
        <div class="d-flex">
            <v-text-field
            label="Barcode:"
            style="margin-left:20px;"
            id="BarCodeNumber"
            v-model="barcode"
            required
            autofocus
            />
        
            <v-btn class="mr-2"
            color="primary"
            elevation="2"
            outlined
            @click="ValidateQrCode(false)"
            style="margin-top: 2vh; margin-left: 1vh;"
            >
                Verder
            </v-btn>
        </div>
        <div>
            <v-list v-if="history !== null" class="history-list">
                <v-list-item class="history-item" v-for="(item, name) in history" :key="name" color="indigo">
                    <div style="width:40px">
                        <b v-if="item.amount < 0" style="color:red;">{{item.amount}}</b>
                        <b v-else>{{item.amount}}</b>
                    </div>
                     <div>
                        {{item.productname}}
                     </div>
                </v-list-item>
            </v-list>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            barcode: null,
            history: null
        }
    },
    props: [
        'CompanyData'
    ],
    watch: {
        'CompanyData.id': function(val) { this.GetHistory(val) },
        barcode(data) {
            if(data.length > 10) {
                this.ValidateQrCode(true)
            }
        }
    },
    methods: {
            ValidateQrCode(HideError) {
                var barcode = document.getElementById('BarCodeNumber');
                if(barcode.value == '') {this.$emit('dialog', "Voer een barcode in", 5000); return} // Check if not empty
                axios.post(this.$api+'/product/create', [
                    {
                        "barcode":barcode.value,
                        "branch": this.CompanyData.id,
                    }
                ],
                {
                    headers: {
                        Authorization: `Bearer `+ localStorage.token
                    }
                })
                .then(succes => {
                    this.$emit('redirect', "product-input", this.CompanyData.id, barcode.value, succes.data.body) // send to parent.
                }
                )
                .catch(error => {
                    if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                    else if(error.response.status == 400 && HideError) {
                            this.$emit('dialog', 'Barcode invalid', 5000);
                    } else {
                        console.error(error)
                    }
                })
            },
            GetHistory(id) {
                const storage = localStorage.getItem('history_list_'+id)
                if(storage !== null) {
                    const object = JSON.parse(storage)
                    this.history = object.history.reverse();
                }
            }
        }
    }
</script>

<style scoped>
    .history-list {
        -ms-overflow-style: none;  /* IE and Edge  Hide scrollbar*/
        scrollbar-width: none;  /* Firefox Hide scrollbar*/
        overflow-y: scroll;
        height: 45vh;
    }

    .history-item:nth-of-type(odd) { background-color:#f0f0f0}
    .history-item:nth-of-type(even) { background-color:#fff}

    .history-item {
        padding: 2px 4px;
        line-height: 1;
        margin-right: 10px;
        margin-left: 10px;
        min-height: 24px;
    }
</style>