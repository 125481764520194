<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Gebruiker Aanmaken</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Voornaam*"
                  v-model="FirstName"
                  :rules="[v => !!v || 'Voornaam is verplicht']"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Tussenvoegsel"
                  v-model="MidName"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Achternaam*"
                  v-model="LastName"
                  :rules="[v => !!v || 'Achternaam is verplicht']"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email*"
                  v-model="Email"
                  :rules="EmailRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Wachtwoord*"
                  v-model="WachtWoord"
                  :rules="PasswordRule"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="selected"
                  :items="Roles"
                  :rules="[v => !!v || 'permissie is verplicht']"
                  label="Role*"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>Met teken (*) zijn verplicht</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="CloseDialog('ShowCreateUser')"
          >
            terug
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="CreateUser"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from 'axios';

  export default {
    data: () => ({
      EmailRules: [
        v => !!v || 'E-mail is verplicht',
        v => /.+@.+\..+/.test(v) || 'Email moet geldig zijn',
      ],
      PasswordRule: [
        v => !!v || "Wachtwoord is verplicht",
        (v) => (v && v.length >= 10) || 'Wachtwoord moet langer dan 10 characters zijn',
      ],
      selected: null,
      Roles:['User', 'Admin'],
      dialog: true,
      FirstName: null,
      MidName: '',
      LastName: null,
      Email: null,
      WachtWoord: null
    }),
    methods: {
      CloseDialog(dialog) {
        this.$emit('CloseDialog', dialog)
      },
      CreateUser() {
        if(this.WachtWoord !== null && this.LastName !== null && this.FirstName !== null && this.selected !== null) {
          axios.post(this.$api+'/user/create', {
            "first_name": this.FirstName,
            "mid_name": this.MidName,
            "last_name": this.LastName,
            "email": this.Email,
            "password": this.WachtWoord,
            "role": this.selected
          }, 
          { headers: { Authorization: `Bearer `+ localStorage.token }})
          .then(succes => {
            this.$emit('dialog', succes.data.body, 5000);
            this.CloseDialog('ShowCreateUser');
          })
          .catch(error => {
            if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
            this.$emit('dialog', error.response.data.body, 5000);
          })
        }
      }
    }
  }
</script>