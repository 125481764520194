<template>
    <div>
          <v-row >
      <v-col
        cols="12"
        sm="12"
      >
          <v-toolbar
            color="elevation-0"
          >
            <v-toolbar-title v-if="ShowLogo" @click="HomePage">
              <v-img
                  max-height="150"
                  max-width="150"
                  src="@/assets/megafone.png"
              ></v-img>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn v-if="Admin" @click="Redirect" icon>
              <v-icon>mdi-account-wrench</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon @click="LogOut">mdi-logout</v-icon>
            </v-btn>
          </v-toolbar>
      </v-col>
    </v-row>
    </div>
</template>

<script>
    import axios from 'axios';

    export default{
        data: () => ({
            ShowLogo: true,
            Admin: false
        }),
        methods: {
          HomePage() {
            this.$emit('redirect', 'company-list');
          },
          IfAdmin() {
            if(localStorage.role == "Admin") {
              if(!window.matchMedia("only screen and (max-width: 480px)").matches) {
                  this.Admin = true
              }
            }
          },
          Redirect() {
              this.$emit('redirect', 'admin-dashboard') // send to parent.
          },
          LogOut() {
            axios.post(this.$api+'/user/logout', {},
            {
                headers: {
                    Authorization: `Bearer `+ localStorage.token
                }
            })
            .then(succes => {
              succes.data.body
              location.reload()
            })
            .catch(error => {
                console.error(error)
            })
          },
        },
        mounted() {
          this.IfAdmin()
        }
    }
</script>
