<template>
    <div>
        <v-card-text></v-card-text>
        <div class="FlexBoxMain">
            <div class="FlexBox1">
                <v-card style="margin-left:10px; margin-right: 10px;">
                <v-card-text>
                    <h5 v-if="ProductName == null" class="d-inline-block float-left">Barcode: {{BarcodeNumber}}</h5>
                    <h5 v-if="ProductName !== null" class="d-inline-block float-left">{{ProductName}}</h5>
                    <h5 class="d-inline-block float-right">Aantal Vandaag: {{ProductAmount}}</h5>    
                    <br>
                </v-card-text>

                <v-card outlined style=" margin-left:10px; margin-right:10px;">
                    <v-progress-circular
                    style="margin-left:15%; margin-top:10px; margin-bottom:10px;"
                    :rotate="89"
                    :size="250"
                    :width="15"
                    :value="numberValue"
                    color="blue">
                        <v-text-field
                        class="NumberField"
                        id="NumberInput"
                        v-model="numberValue"
                        v-on:change="CheckInteger"
                        v-on:keyup.enter="InsertProduct('add')"
                        hide-details
                        size="3"
                        single-line
                        style="font-size:25px;"
                        type="number"
                        />
                </v-progress-circular>
                <v-spacer style="margin-top:10px;"/>
                </v-card>
                <v-btn v-if="ShowAddButton"
                color="primary"
                elevation="5"
                style="float:right; margin-top:15px; margin-right:10px;"
                @click="InsertProduct('add')"
                >
                Toevoegen
                </v-btn>
                <v-btn v-if="!ShowAddButton"
                color="primary"
                elevation="5"
                style="float:right; margin-top:15px; margin-right:10px;"
                @click="InsertProduct('change')">
                    Aanpassen
                </v-btn>
                <v-btn v-if="ShowTotalButton"
                color="primary"
                elevation="5"
                style="float:right; margin-top:15px; margin-right:10px;"
                @click="EditProductValue">
                    Totaal Aanpassen
                </v-btn>
                <br><br><br>
                </v-card>
            </div>

            <div class="FlexBox2 FlexBox2Hide">
                FLEX BOX 2
            </div>

            <div class="FlexBox3 FlexBox3Hide">
                FLEX BOX 2
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                numberValue: null,
                ShowAddButton: true,
                ShowTotalButton: false,
                ProductName: null
            }
        },
        props: [
            'CompanyId',
            'BarcodeNumber',
            'ProductAmount'
        ],
        mounted() {
            this.BarcodeToName();
        },
        methods: {
            EditProductValue() {
                this.ShowAddButton = false;
                this.numberValue = this.ProductAmount;
            },
            InsertProduct(option) {
                var number;
                if(option == "add") {
                    if(this.ProductAmount !== 0) {
                        number = parseInt(this.numberValue) + parseInt(this.ProductAmount);
                    } else {
                        number = parseInt(this.numberValue)
                    }
                } else if(option == "change") {
                    number = this.numberValue;
                }
                
                axios.post(this.$api+'/product/create', [{
                    "barcode":this.BarcodeNumber,
                    "branch": this.CompanyId,
                    "added":number,
                    "user":localStorage.id
                }],
                { headers: { Authorization: `Bearer `+ localStorage.token } })
                .then(this.HistoryList())
                .catch(error => { 
                    if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                    console.error(error); 
                    })
            },
            CheckInteger(data) { 
                if(data == '' || data.match(/[a-z]/i)) { this.numberValue = 0; } 
            },
            BarcodeToName() {
                axios.post(this.$api+'/barcode/get', 
                {}, { headers: { Authorization: `Bearer `+ localStorage.token } })
                .then(succes => {
                    for(var i=0; i<succes.data.length; i++) {
                        if(succes.data[i].barcode == this.BarcodeNumber) {
                            this.ProductName = succes.data[i].short;
                            break;
                        }
                    }
                })
                .catch(error => { console.error(error); })
            },
            HistoryList() {
                // this function will store the item in localstorage
                if(this.numberValue !== 0) { 
                    let storage = localStorage.getItem('history_list_'+this.CompanyId);
                    if(storage == null) {
                        localStorage.setItem('history_list_'+this.CompanyId, JSON.stringify({
                            'date': new Date().toISOString().slice(0, 10), // 2022-01-01
                            'history': []
                        }));
                        storage = localStorage.getItem('history_list_'+this.CompanyId);
                    }
                    var array = JSON.parse(storage);
                    array.history.push({
                        'productname': this.ProductName,
                        'amount': this.numberValue,
                    });
                    
                    localStorage.setItem('history_list_'+this.CompanyId, JSON.stringify(array));
                } // if number 0 ignore it.
                this.$emit('redirect', "product-comp", this.CompanyId)
            }
        }
    }
</script>

<style scoped>

    .NumberField {
        margin: auto;
        width: 100px;
    }

    .FlexBoxMain {
        display: flex;
        height: 80vw;
    }

    .FlexBox1 {
        order:1;
        width: 100vw;
    }

    .FlexBox2 {
        display: none; /* Disabled */
        order:2;
        margin-left:6vh;
        width: 50vw;
        background-color:blue;
    }

    @media only screen and (max-width: 860px) {
        .FlexBox2Hide { display:none; }
    }

    @media only screen and (max-width: 100vw) {
        .FlexBox1 { width: 400px;}
    }

    .FlexBox3 {
        display: none; /* Disabled */
        order:3;
        margin-left:6vh;
        width: 50vw;
        background-color:orange;
    }

    @media only screen and (max-width: 1320px) {
        .FlexBox3Hide { display:none; }
    }

</style>