<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Barcode Aanmaken</span>
        </v-card-title>
        <v-card-text>
          <v-container>
              <v-form v-model="valid">
                <v-container>
                    <v-text-field
                        v-model="title"
                        :rules="Rules"
                        label="Product Naam"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="title_short"
                        :rules="Rules"
                        label="Product Naam (Kort)"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="barcode"
                        :rules="Rules"
                        label="Barcode"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="ArticleNumb"
                        :rules="Rules"
                        label="Artikelnummer"
                        required
                    ></v-text-field>

                    <v-select
                    v-model="select"
                    hint="Selecteer een status"
                    :items="items"
                    persistent-hint
                    return-object
                    single-line
                    ></v-select>
                </v-container>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="CloseDialog('ShowCreateBarcode')"
          >
            Terug
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="ValidateBarcode"
          >
            Aanmaken
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
    import axios from 'axios';

    export default {
        data: () => ({
            select: 'Active',
            items: [
                'Active','Disabled','Hidden'
            ],
            dialog: true,
            valid: false,
            title: '',
            title_short:'',
            ArticleNumb:'',
            barcode: '',
            Rules: [
                v => !!v || 'Dit is verplicht',
            ],
        }),
        methods: {
            CloseDialog(dialog) {
              this.$emit('CloseDialog', dialog)
            },
            ValidateBarcode() {
                if(this.valid) {
                    axios.post(this.$api+'/barcode/create', {
                        "barcode": this.barcode,
                        "article_number": this.ArticleNumb,
                        "status": this.select,
                        "title": this.title,
                        "title_short": this.title_short
                    }, {
                        headers: {
                            Authorization: `Bearer `+ localStorage.token
                        }
                    })
                    .then(succes => {
                        this.$emit('dialog', succes.data.body, 5000);
                        this.CloseDialog('ShowCreateBarcode')
                    })
                    .catch(error => {
                        if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                        this.$emit('dialog', error.response.data.body, 5000);
                    })
                }
            }
        },
        mounted() {
        }
    }
</script>