<template>
        <div class="SideBar">
        <barcode-create 
            v-if="ShowCreateBarcode"
            style="order:100;"
            @CloseDialog="DialogClose"
            @dialog="ReturnDialog"
        />

        <barcode-edit 
            v-if="ShowEditBarcode"
            style="order:100;"
            @CloseDialog="DialogClose"
            @dialog="ReturnDialog"
        />

        <user-create 
            v-if="ShowCreateUser"
            style="order:100;"
            @CloseDialog="DialogClose"
            @dialog="ReturnDialog"
        />

        <user-edit 
            v-if="ShowEditUser"
            style="order:100;"
            @CloseDialog="DialogClose"
            @dialog="ReturnDialog"
        />

        <user-get 
            v-if="ShowGetUser"
            style="order:100;"
            @CloseDialog="DialogClose"
        />

        <company-create
            v-if="ShowCreateCompany"
            style="order:100;"
            @CloseDialog="DialogClose"
            @dialog="ReturnDialog"
        />

        <company-edit
            v-if="ShowEditCompany"
            style="order:100;"
            @CloseDialog="DialogClose"
            @dialog="ReturnDialog"
        />
        
          <v-navigation-drawer permanent expand-on-hover>
            <v-list>
            <v-list-item link>
                <v-list-item-content>
                <v-list-item-title class="text-h6">{{AdminInfo.name}}</v-list-item-title>
                <v-list-item-subtitle>{{AdminInfo.email}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            </v-list>

            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item @click="Redirect('company-list')" link>
                    <v-list-item-icon>
                        <v-icon>mdi-office-building</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Bedrijven</v-list-item-title>
                </v-list-item>
                <v-list-item @click="ShowDialog('ShowCreateCompany')" link>
                    <v-list-item-icon>
                        <v-icon>mdi-office-building</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Bedrijf Toevoegen</v-list-item-title>
                </v-list-item>
                <v-list-item @click="ShowDialog('ShowEditCompany')" link>
                    <v-list-item-icon>
                        <v-icon>mdi-office-building</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Bedrijf Aanpassen</v-list-item-title>
                </v-list-item>

                  <v-list-item @click="ShowDialog('ShowGetUser')" link>
                    <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Gebruikers</v-list-item-title>
                </v-list-item>
                <v-list-item @click="ShowDialog('ShowCreateUser')" link>
                    <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Gebruiker Aanmaken</v-list-item-title>
                </v-list-item>
                <v-list-item @click="ShowDialog('ShowEditUser')" link>
                    <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Gebruiker Aanpassen</v-list-item-title>
                </v-list-item>
                <v-list-item @click="ShowDialog('ShowCreateBarcode')" link>
                    <v-list-item-icon>
                    <v-icon>mdi-barcode</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Barcode Aanmaken</v-list-item-title>
                </v-list-item>
                <v-list-item @click="ShowDialog('ShowEditBarcode')" link>
                    <v-list-item-icon>
                    <v-icon>mdi-barcode</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Barcode Aanpassen</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import BarCodeCreate from '/src/components/AdminPanel/A-Barcode-Create.vue';
import BarCodeEdit from '/src/components/AdminPanel/A-Barcode-Edit.vue';
import UserCreate from '/src/components/AdminPanel/A-User-Create.vue';
import UserEdit from '/src/components/AdminPanel/A-User-Edit.vue';
import UserGet from '/src/components/AdminPanel/A-User-Get.vue';
import CompanyCreate from '/src/components/AdminPanel/A-Company-Create.vue';
import CompanyEdit from '/src/components/AdminPanel/A-Company-Edit.vue';

  export default {
    data() {
        return {
            ShowCreateBarcode:false,
            ShowEditBarcode:false,
            ShowCreateUser:false,
            ShowEditUser:false,
            ShowGetUser:false,
            ShowCreateCompany:false,
            ShowEditCompany: false,
            AdminInfo: {
                name: localStorage.name,
                email: localStorage.email,
            }
        }
    },
    components: {
        'barcode-create':BarCodeCreate,
        'barcode-edit':BarCodeEdit,
        'user-create':UserCreate,
        'user-edit':UserEdit,
        'user-get':UserGet,
        'company-create':CompanyCreate,
        'company-edit':CompanyEdit,

    },
    methods: {
        ReturnDialog(text, timer){
            this.$emit('dialog', text, timer);  
        },
        ShowDialog(Show) {
            switch(Show) {
                case "ShowCreateBarcode": this.ShowCreateBarcode = true; break;
                case "ShowEditBarcode": this.ShowEditBarcode = true; break;
                case "ShowCreateUser": this.ShowCreateUser = true; break;
                case "ShowEditUser": this.ShowEditUser = true; break;
                case "ShowGetUser": this.ShowGetUser = true; break;
                case "ShowCreateCompany": this.ShowCreateCompany = true; break;
                case "ShowEditCompany": this.ShowEditCompany = true; break;
            }
        },
        DialogClose(item) {
            switch(item) {
                case "ShowCreateBarcode": this.ShowCreateBarcode = false; break;
                case "ShowEditBarcode": this.ShowEditBarcode = false; break;
                case "ShowCreateUser": this.ShowCreateUser = false; break;
                case "ShowEditUser": this.ShowEditUser = false; break;
                case "ShowGetUser": this.ShowGetUser = false; break;
                case "ShowCreateCompany": this.ShowCreateCompany = false; break;
                case "ShowEditCompany": this.ShowEditCompany = false; break;
            }
        },
        Redirect(data) {
            this.$emit('redirect', data) // send to parent.
        }
    }
  }
</script>

<style scoped>
    .SideBar {
        order:1;
        height: 100%;
        width:10px;
        position: fixed;
    }
</style>