<template>
    <div>
        <v-card
        elevation="2"
        >
            <!-- Header of Card -->
            <v-toolbar-title class="card-header">
                Excel Download
            </v-toolbar-title>
            
                <v-autocomplete style="min-width: 300px"
                @change="GetDatesCompany()"
                class="autocomplete"
                label="Zoek bedrijf"
                :items="names"
                item-text="name"
                item-value="id"
                auto-select-first
                v-model="selectedBranch"
                ></v-autocomplete>

                <div class="card">
                    <v-autocomplete style="padding-left: 10px; min-width: 150px"
                    class="textfield"
                    label="Datum"
                    :items="dates"
                    :disabled="!DatesShowList"
                    auto-select-first
                    v-model="selectedDate"
                    ></v-autocomplete>

                    <v-btn @click="GetExcel()" color="primary" class="card-button" elevation="2" :disabled="!DatesShowList">
                        Download
                    </v-btn>
                </div>
        </v-card>        
    </div>
</template>

<script>
    import axios from 'axios';

export default {
    data() {
        return {
            ShowExcel: false,
            date_today: true,
            CompanyList: null,
            selectedBranch: {id: null, name: null},
            selectedDate: null,
            dates: [],
            names: [],
            DatesShowList: false
        }
    },
    methods: {
        GetExcel() {
            if(this.selectedDate !== null) {            
                var date;
                if(this.selectedDate === undefined) {
                    var today = new Date();   
                    date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear();
                } else {
                    date = this.selectedDate;
                }

                var data = '?Authorization='+localStorage.token + '&company_id='+this.selectedBranch + '&date='+date;
                window.open(this.$api+'/product/export'+data, '_blank').focus();
                // Reset selected Branch & Date.
                this.selectedBranch = null; 
                this.selectedDate = null;
                this.dates = null;
            }
        },
        GetCompanylist() {
            axios.post(this.$api+'/branch/get', {}, { headers: { Authorization: `Bearer `+ localStorage.token } })
            .then(response => {
                this.CompanyList = response.data;
                for(var i=0; i < response.data.length; i++) {
                    this.names.push({id:response.data[i].id, name:response.data[i].name},);
                }
            })
            .catch(error => { console.error(error); })
        },
        GetDatesCompany() { // Get product dates for company
            axios.post(this.$api+'/product/date', {company_id: this.selectedBranch}, { headers: { Authorization: `Bearer `+ localStorage.token } })
            .then(response => {
                this.dates = response.data.body.reverse();
                if(response.data.body.length === 0) {
                    this.DatesShowList = false;
                } else {
                    this.DatesShowList = true;
                }
            })
            .catch(error => {
                if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                console.error(error); 
            })
        }
    },
    mounted() {
        this.GetCompanylist();
    }
}
</script>

<style scoped>
    .card {
        display: flex;
    }

    .card-button {
        margin: 14px
    }

    .autocomplete {
        margin-left: 2vh;
        max-width: 20vh;
    }

    .textfield {
        margin-left: 1vh;
        margin-right: 1vh;
        max-width: 19vh;
    }

    .card-header {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
    }
</style>
