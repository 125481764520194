<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="show"
      :timeout="timeout"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
    name: 'DialogComponent',
    props: {
        'text': String,
        'timeout': Number,
    },
    data() {
        return {
            show: true
        }
    },
}
</script>