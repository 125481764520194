<template>
    <div style="max-height: 100%">
        <br>
        <v-data-table
        @click:row="handleClick"
        :headers="headers"
        :items="product_history"
        item-key="name"
        class="elevation-1"
        :footer-props="{
        'items-per-page-options': [4],
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus'
        }"
        ></v-data-table>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        data() {
            return {
                headers: [
                    {
                        text: 'Product',
                        align: 'left',
                        sortable: false,
                        value: 'title'
                    },
                    {
                        text: 'Aantal',
                        value: 'added',
                        align: 'left',
                        sortable: false
                    }
                ],
                product_history: [],
            }
        },
        props: [
            'CompanyId'
        ],
        methods: {
            GetHistoryLog() {
                axios.post(this.$api+'/product/history', {
                    company_id:this.CompanyId,
                    date: new Date().toISOString().split('T')[0] // Get today's date
                },
                {
                    headers: {
                        Authorization: `Bearer `+ localStorage.token
                    }
                })
                .then(succes => {
                    this.product_history = succes.data;
                })
                .catch(error => {
                    if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                    console.error(error)
                })
            },
            handleClick(item) {
                // if you select a barcode you want to edit in the table you get redirected to input to be validated.
                this.$emit('redirect', "product-input", this.CompanyId, item.barcode, item.added) // send to parent.
            }
        },
        mounted() {
            this.GetHistoryLog();
        }
    }
</script>