<template>
    <div>
        <v-card-text></v-card-text>
        <div class="FlexboxMain">
            <div class="FlexBox1">
                <v-card
                elevation="10"
                outlined
                min-height="600"
                style="margin-left:10px; margin-right:10px;">

                    <!-- Header of Card -->
                    <div class="card-header">
                        <!-- return to previous page -->
                        <v-btn 
                        icon
                        color="black"
                        x-large
                        @click="ReturnPage"
                        >
                        <v-icon>mdi-keyboard-backspace</v-icon>
                        </v-btn>

                        <v-card-title 
                        v-if="CompanyData !== null" 
                        primary-title
                        style="font-size:25px;">
                            {{CompanyData.name}}
                        </v-card-title>
                    </div>
                    <br>
                    <!-- Body of Card -->
                    <div class="options">
                        <v-btn 
                            v-if="true"
                            icon
                            color="black"
                            x-large
                            @click="BarcodeScanner"
                        >
                            <v-icon>mdi-barcode-scan</v-icon>
                        </v-btn>

                        <v-btn 
                            icon
                            color="black"
                            x-large
                            @click="SelectedDialog('ShowBarcodeInput')"
                        >
                            <v-icon>mdi-tag-search</v-icon>
                        </v-btn>

                        <v-btn 
                            icon
                            color="black"
                            x-large
                            @click="SelectedDialog('ShowProductList')"
                        >
                            <v-icon>mdi-playlist-edit</v-icon>
                        </v-btn>

                        <v-btn 
                            icon
                            color="black"
                            x-large
                            @click="SelectedDialog('ShowHistory')"
                        >
                            <v-icon>mdi-history</v-icon>
                        </v-btn>

                        <send-message 
                            :items-in-list="this.ItemsInList" 
                            :company-id="this.CompanyId" 
                            @ReturnDialog="ReturnDialog"
                            @redirect="Redirect"
                            @save-products-list="SaveProduct"
                        />
                    </div>

                <!-- Barcode input dialog -->
                <product-barcode-input @redirect="Redirect" :company-data="CompanyData" v-if="ShowDialog.ShowBarcodeInput" />
                <!-- Show Product History log -->
                <product-history @redirect="Redirect" :company-id="this.CompanyId" v-if="ShowDialog.ShowHistory" />

                <product-list  
                    @ReturnDialog="ReturnDialog"
                    @update-count="ShowListDialog" 
                    v-if="ShowDialog.ShowProductList"
                    :company-id="this.CompanyId"
                    :save-products="SaveProductList"
                />
                </v-card>
            </div>

            <div class="FlexBox2 flexbox2Hide">
                FLEXbox 2
            </div>

            <div class="FlexBox3 flexbox3Hide">
                FLEXbox 3
            </div>

        </div>
    </div>    
</template>

<script>
    import axios from 'axios';
    import ProductBarcodeInput from './ProductBarcodeInput.vue';
    import ProductList from '/src/components/Product/ProductList.vue';
    import ProductHistory from './ProductHistory.vue';
    import SendMessage from '../SendMessage.vue';

    export default {
        mounted() {
            this.GetCompany(); // Get company data.

            // Check if user selected show history.
            this.SelectedDialog();
        },
        components: {
            'product-list':ProductList,
            'product-barcode-input':ProductBarcodeInput,
            'product-history':ProductHistory,
            'send-message':SendMessage
        },
        data() {
            return {
                CompanyData: null,
                ShowDialog: {
                    ShowHistory: false,
                    ShowBarcodeInput: false,
                    ShowProductList: false,
                },
                ItemsInList: false,
                SaveProductList: false,
            }
        },
        props: [
            'CompanyId'
        ],
        methods: {
            SaveProduct() {
                this.SaveProductList = true;
            },
            ShowListDialog(boolean) {
                this.ItemsInList = boolean; // show added items to list wil be added to cart.
            },
            SelectedDialog(data) {
                // Change dialog to selected dialog.
                if(data !== undefined) {
                    // Set everything on false
                    Object.keys(this.ShowDialog).forEach(key => {
                        this.ShowDialog[key] = false;
                        localStorage.setItem(key, false);
                    });
                    
                    // Select item
                    eval("this.ShowDialog."+data+" = true;"); // show dialog selected.
                    localStorage.setItem(data, true);

                } else {
                    // Select last selected dialog
                    if(localStorage.ShowProductList == 'true') { this.ShowDialog.ShowProductList = true; } else { this.ShowDialog.ShowProductList = false; }
                    if(localStorage.ShowBarcodeInput == 'true') { this.ShowDialog.ShowBarcodeInput = true;} else { this.ShowDialog.ShowBarcodeInput = false; }
                    if(!this.ShowDialog.ShowBarcodeInput && !this.ShowDialog.ShowProductList) { this.ShowDialog.ShowHistory = true; }
                }
            },
            GetCompany() {
                axios.post(this.$api+'/branch/get/'+ this.CompanyId, {},
                {
                    headers: {
                        Authorization: `Bearer `+ localStorage.token
                    }
                })
                .then(succes => {
                    this.CompanyData = succes.data;
                })
                .catch(error => {
                    if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                    console.error(error);
                })
            },
            ReturnPage() {
                this.$emit('redirect', "company-list") // send to parent.
            },
            Redirect(url, id, id2, id3) {
                this.$emit('redirect', url, id, id2, id3) // send to parent.
            },
            BarcodeScanner() {
                this.$emit('redirect', 'product-scanner', this.CompanyData.id) // send to parent.
            },
            ReturnDialog(text, timer){
                this.$emit('dialog', text, timer);  
            }
        }
    }
</script>

<style scoped>
    .FlexboxMain {
        display:flex;
        max-height:85vh;
    }

    .FlexBox1 {
        order:1;
        width: 100%;
        max-width: 450px;
    }

    .FlexBox2 {
        display: none; /* Disabled */
        order:2;
        margin-left:10vh;
        width: 30%;
        background-color:green;
    }

    @media only screen and (max-width: 700px) { .flexbox2Hide { display:none; } }

    .FlexBox3 {
        display: none; /* Disabled */
        order:2;
        margin-left:10vh;
        width: 30%;;
        background-color:purple;
    }

    @media only screen and (max-width: 1050px) { .flexbox3Hide { display:none; }}
    .card-header { display: inline-flex; }

    .options {
        margin: 25px;
        display: flex;
        justify-content: center;
    }
</style>