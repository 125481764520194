<template>
    <div>
        <v-card-text><!-- Spacer --></v-card-text>
        <div style="display:flex">
            <div style="order: 1; min-height:85vh; max-width:50vh">
                <v-card elevation="2" style="margin-left:4vh; width:85%;">
                    <v-card-text>barcode Scanner</v-card-text>
                    <div style="margin-left:10px; margin-right:10px;">
                        <barcode-reader @decode="onDecode" @loaded="onLoaded"></barcode-reader> 
                        <div v-if="!LoadedCam"><v-img src="@/assets/NoCamera.jpg"/></div>
                        <v-divider style="margin-top:20px; margin-bottom:10px;"></v-divider>
                        <div v-if="CompanyData !== null">
                            <b>Bedrijf:</b> {{CompanyData.name}} <br>
                            <b>Address:</b> {{CompanyData.address}} <br>
                            <b>Postcode:</b> {{CompanyData.postalcode}}
                        </div>
                        <v-divider style="margin-top:10px;"></v-divider>
                        <v-btn class="mr-2 mt-2 mb-2"
                        color="primary"
                        elevation="2"
                        outlined
                        @click="ReturnPage"
                        >
                        Terug
                        </v-btn>
                    </div>
                </v-card>
            </div>

            <div class="FlexBox2 flexbox2Hide">
                Flexbox 2 voor product lijst
            </div>

            <div class="FlexBox3 flexbox3Hide">
                Flexbox 3 voor andere items
            </div>
        </div>
    </div>
</template>

<script>
    import { StreamBarcodeReader } from "vue-barcode-reader";
    import axios from 'axios';

    export default {
        data() {
            return {
                LoadedCam: false,
                CompanyData: null
            }
        },
        components: {
            'barcode-reader':StreamBarcodeReader,
        },
         props: [
            'CompanyId'
        ],
        methods: {
            onLoaded() {
                this.LoadedCam = true
            },
            onDecode(barcode) {
                axios.post(this.$api+'/product/create', [{
                    "barcode":parseInt(barcode),
                    "branch": this.CompanyId,
                    "user":localStorage.id
                }],
                { headers: { Authorization: `Bearer `+ localStorage.token} })
                .then(succes => { this.$emit('redirect', "product-input", this.CompanyId, parseInt(barcode), parseInt(succes.data.body))})
                .catch(error => {
                    if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                    else if(error !== 'undefined') { this.$emit('dialog', error.response.data.body, 5000);
                    } else {
                        this.$emit('dialog', 'Please scan again', 5000);
                    }
                })
            },
            GetCompany() {
                axios.post(this.$api+'/branch/get/'+ this.CompanyId, {},
                {
                    headers: { Authorization: `Bearer `+ localStorage.token }
                })
                .then(succes => {
                    this.CompanyData = succes.data;
                })
                .catch(error => {
                    if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
                    console.error(error);
                })
            },
             ReturnPage() {
                this.$emit('redirect', "company-list") // send to parent.
            },
        },
        mounted() {
            this.GetCompany();
        }
    }
</script>

<style scoped>
    .FlexBox2 { 
        display: none; /* Disabled */
        order:2; 
        width:50vh; 
        background-color:red;
    }

    .FlexBox3 {
        display: none; /* Disabled */
        order:3; 
        margin-left: 5%; 
        width:50vh; 
        background-color:blue;
    }

    @media only screen and (max-width: 925px) {
    .flexbox2Hide { display:none; }
    }
    @media only screen and (max-width: 1100px) {
    .flexbox3Hide { display:none; }
    }
</style>