import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false;
// Vue.prototype.$api = 'http://127.0.0.1:8000/public/api'; // Api url
Vue.prototype.$api = 'https://api.megafone.nl/public/api'; // Api url

const app = new Vue({
  vuetify,
  render: v => v(App)
});

app.$mount('#app');