<template>
  <v-app style="background-color:gray">
    <v-main>
        <menu-bar @redirect="Redirect" v-if="ShowMenuBar"/>
        <component
          @redirect="Redirect" 
          @validate-login="ValidateLogin"
          @dialog="Dialog"
          :company-id="this.CompanyId"
          :barcode-number="this.BarCodeNumber"
          :product-amount="this.ProductAmount"
          v-bind:is="component"
        />
        <dialog-comp v-if="dialog.show" :text="this.dialog.text" :timeout="this.dialog.timeout"/>
    </v-main>
  </v-app>
</template>

<script>
  import MenuBar from './components/MenuBar.vue';
  import Logincomp from './components/login';
  import CompanyList from './components/CompanyList';
  import ProductComp from './components/Product/ProductComp.vue';
  import ProductInput from './components/Product/ProductInput.vue';
  import ProductScanner from './components/Product/ProductScanner.vue';
  import dashboardAdmin from './components/AdminPanel/A-dashboard.vue';
  import DialogComp from './components/dialog.vue';

  export default {
    name: 'App',
    components: {
      'login-comp':Logincomp,
      'company-list':CompanyList,
      'product-comp':ProductComp,
      'product-input':ProductInput,
      'product-scanner':ProductScanner,
      'menu-bar':MenuBar,
      'admin-dashboard':dashboardAdmin,
      'dialog-comp':DialogComp
      },
    data() {
      return {
        component:'login-comp',
        CompanyId: null,
        BarCodeNumber: null,
        ProductAmount: null,
        dialog: {
          text: null,
          timeout: null,
          show: false
        },
        ShowMenuBar: false
      }
    },
    methods: {
      ValidateLogin(value) {
        if(value == "True") {
            this.component = 'company-list';
            this.ShowMenuBar = false
        }
      },
      Redirect(url, id, id2, id3) {
        // TEMP 
        if(url == 'company-list') {
          this.ShowMenuBar = false;
        } 
        else {
          this.ShowMenuBar = true;
        }
          
        this.component = url;
        
        if(id !== undefined) {
          this.CompanyId = id;
        }
        if(id2 !== undefined) {
          this.BarCodeNumber = id2
        }
        if(id3 !== undefined) {
          this.ProductAmount = id3
        }
      },
      Dialog(text, timeout) {
        this.dialog.text = text;
        this.dialog.timeout = timeout;
        this.dialog.show = true;

        if(this.dialog.show === true) {
          setTimeout(() => {
            this.dialog.show = false
          }, timeout);
        }
      }
    },
  };
</script>
