
<template>
  <div>
  <v-row >
      <v-col
        cols="12"
        sm="12"
      >
          <v-toolbar
            color="elevation-0"
          >
            <v-toolbar-title v-if="ShowLogo">
              <v-img
                  max-height="150"
                  max-width="150"
                  src="@/assets/megafone.png"
              ></v-img>
            </v-toolbar-title>

            <v-autocomplete v-if="!ShowLogo"
              @change="Redirect"
              auto-select-first
              v-model="selectedBranch"
              :loading="loading"
              :items="names"
              :search-input.sync="search"
              item-text="name"
              item-value="id"
              return-object
              cache-items
              flat
              hide-no-data
              hide-details
              solo-inverted
              style="max-width:350px"
            ></v-autocomplete>
            
            <v-spacer></v-spacer>
            <v-btn v-if="Admin" @click="RedirectAdmin" icon>
              <v-icon>mdi-account-wrench</v-icon>
            </v-btn>
            <v-btn @click="SearchFunction" icon>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn @click="LogOut" icon>
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </v-toolbar>
      </v-col>
    </v-row>
    <v-card-text></v-card-text>
    <div style="display:flex">
      <div class="FlexBox1">
        <v-card
            class="mx-auto"
            width="350"
            style="float: left; margin-left:1.5vh !important;"
          >
          <v-list three-line>
            <template v-for="(Branch, index) in Branches">
              <v-subheader
                v-if="Branch.header"
                :key="Branch.header"
              ></v-subheader>

              <v-divider
                v-else-if="Branch.divider"
                :key="index"
              ></v-divider>

              <v-list-item
                v-else
                :key="Branch.name"
              >
              <v-list-item-avatar>
                <v-img :src="Branch.icon"></v-img>
              </v-list-item-avatar>

              <v-list-item-content @click="ClickCompany(Branch.id, Branch.name)">
                <v-list-item-title v-html="Branch.name"></v-list-item-title>
                <v-list-item-subtitle v-if="Branch.city_name == null" v-html="Branch.address +', '+ Branch.postalcode"></v-list-item-subtitle>
                <v-list-item-subtitle v-if="Branch.city_name !== null" v-html="Branch.address +', '+ Branch.postalcode + ', ' + Branch.city_name"></v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </div>

      <div class="FlexBox2 flexbox2Hide">
        FLEXBOX2
      </div>
      
      <div class="FlexBox3 flexbox3Hide">
        FLEXBOX 3
      </div>

    </div>
    
     
  </div>
</template>
<script>
    import axios from 'axios';

    export default {
        data: () => ({
          loading: false,
          names: [],
          search: null,
          ShowLogo: true,
          selectedBranch: {id: null, name: null},
          Admin: false,
          Branches: [
            // Get From api
          ],
    }),
    methods: {
      LogOut() {
          axios.post(this.$api+'/user/logout', {},
          {
              headers: {
                  Authorization: `Bearer `+ localStorage.token
              }
          })
          .then(succes => {
            succes.data.body
            location.reload()
          })
          .catch(error => {
              console.error(error)
          })
        },
        SearchFunction() {
          this.ShowLogo = !this.ShowLogo;
        },
        ClickCompany(id, name) {
          this.selectedBranch = {id: id, name: name};
          this.Redirect();
        },
        IfAdmin() {
            if(localStorage.role == "Admin") {
              if(!window.matchMedia("only screen and (max-width: 480px)").matches) {
                  this.Admin = true
              }
            }
        },
        Redirect() {
          this.$emit('redirect', 'product-comp', this.selectedBranch.id) // send to parent.
        },
        RedirectAdmin() {
              this.$emit('redirect', 'admin-dashboard') // send to parent.
        },
        GetCompanyList() {
          axios.post(this.$api+'/branch/get', {}, {
            headers: {
                Authorization: `Bearer `+ localStorage.token
            }
          })
          .then(response => {
            let comp = [];
            for (let i = 0; i < response.data.length; ++i) {
              if(i !== response.data.length && i !== 0) {
                comp.push({ divider: true, inset: true },);
              }
              comp.push(response.data[i]);
              this.names.push({id:response.data[i].id, name:response.data[i].name},);
            }
            this.Branches = comp;
          })
          .catch(error => {
            if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
            console.error(error);
          });
        },
      },
      mounted() {
        this.GetCompanyList();
        this.IfAdmin();
      },
      watch: {
        search (val) {
          if(val !== null) {
            val && val !== this.selectedBranch.name;
          }
        },
      },
    }
</script>

<style scoped>
  html {
    overflow: hidden !important;
  }

  .FlexBox1 {
    order: 1; 
    margin-left:2vh; 
    max-height:75vh; 
    overflow:scroll;
    -ms-overflow-style: none;  /* Hide Scrollbar IE and Edge */
    scrollbar-width: none;  /* Hide Scrollbar Firefox */
  }

  .FlexBox1::-webkit-scrollbar {
    display: none;
  }

  .FlexBox2 {
    display: none; /* Disabled */
    background-color: green;
    margin-left: 3.5vh;
    width:50vh;
    max-height:75vh; 
    order: 2;
  }

  @media only screen and (max-width: 825px) {
    .flexbox2Hide { display:none; }
    }

  .FlexBox3 {
    display: none; /* Disabled */
    background-color: red;
    margin-left: 3.5vh;
    width:50vh;
    order: 2;
  }

  @media only screen and (max-width: 1310px) {
    .flexbox3Hide { display:none; }
    }

</style>
