<template>
  <div>
    <v-card 
    class="mx-auto"
    max-width="344"
    style="margin-top: 140px;"
    elevation="6">
      <v-card-text>
        <v-img
          max-height="350"
          max-width="350"
          src="@/assets/megafone.png"
        ></v-img>
        <div class="text--primary">
          <!-- Email -->
          <v-text-field
            label="Email"
            id="Email"
          ></v-text-field>
          <!-- Password -->
          <v-text-field
            label="Wachtwoord"
            type="password"
            id="Password"
            v-on:keyup.enter="ValidateLogin()"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-right">
          <v-btn @click="ValidateLogin" text color="red accent-4" elevation="2" large>
            Login
          </v-btn>
        </v-col>
      </v-card-actions>
  </v-card>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'LoginForm',
    methods: {
      ValidateLogin() {
        axios({
          method: 'post',
          url: this.$api+'/login',
          data: {
            "email":document.getElementById('Email').value,
            "password":document.getElementById('Password').value
          }
        })
        .then(response => {
          this.StoreUser(response);
          this.$emit('validate-login', "True"); // send to parent.
        })
        .catch(error => {
          this.$emit('dialog', error.response.data.body, 5000);
        });
      },
      CheckSignedIn() {
        axios.post(this.$api+'/user/get/'+localStorage.id, {},
        {
          headers: {
            Authorization: `Bearer `+ localStorage.token
          }
        })
        .then(response => {
          this.$emit('validate-login', "True") // send to parent.
          response
        })
        .catch(error => {
          console.error(error);
        });
      },
      StoreUser(response) {
        localStorage.clear();
          localStorage.id = response.data.id;
          localStorage.name = response.data.name;
          localStorage.email = response.data.email;
          localStorage.role = response.data.role;
          localStorage.token = response.data.token;
      }
    },
    mounted() {
      this.CheckSignedIn();
    }
  }
</script>
