<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Gebruiker Aanpassen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                    @change="GetUser"
                    auto-select-first
                    v-model="SelectedUser"
                    :items="Users"
                    item-text="first_name"
                    item-value="id"
                    label="Selecteer een gebruiker"
                  />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Voornaam*"
                  v-model="FirstName"
                  :rules="[v => !!v || 'Voornaam is verplicht']"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Tussenvoegsel"
                  v-model="MidName"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Achternaam*"
                  v-model="LastName"
                  persistent-hint
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Email*"
                  v-model="Email"
                  :rules="EmailRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Wachtwoord*"
                  v-model="WachtWoord"
                  :rules="PasswordRule"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="selected"
                  :items="Roles"
                  :rules="[v => !!v || 'permissie is verplicht']"
                  label="Role*"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>Met teken (*) zijn verplicht</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="CloseDialog('ShowEditUser')"
          >
            terug
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="EditUser"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from 'axios';

  export default {
    data: () => ({
      EmailRules: [
        v => !!v || 'E-mail is verplicht',
        v => /.+@.+\..+/.test(v) || 'Email moet geldig zijn',
      ],
      PasswordRule: [
        (v) => (v && v.length >= 10) || 'Wachtwoord moet langer dan 10 characters zijn',
      ],
      selected: null,
      Roles:['User', 'Admin'],
      Users: [],
      SelectedUser: null,
      dialog: true,
      FirstName: null,
      MidName: '',
      LastName: null,
      Email: null,
      WachtWoord: ''
    }),
    mounted() {
      this.GetUsers();
    },
    methods: {
      GetUser(data) {
        for(let i = 0; i < this.Users.length; i++) {
          if(this.Users[i].id === data) {
            this.FirstName = this.Users[i].first_name;
            this.MidName = this.Users[i].mid_name;
            this.LastName = this.Users[i].last_name;
            this.Email = this.Users[i].email;
            this.selected = this.Users[i].role;
          }
        }
      },
      CloseDialog(dialog) {
        this.$emit('CloseDialog', dialog)
      },
      EditUser() {
        if(this.Email !== null & this.selected !== null & this.SelectedUser !== null) {
          let items = new Object();
          items.id = this.SelectedUser;
          if(this.Email !== null) { items.email = this.Email;}
          if(this.WachtWoord !== "") { items.password = this.WachtWoord;}
          if(this.selected !== null) { items.selected = this.selected;}

          axios.post(this.$api+'/user/edit', items, 
          { headers: { Authorization: `Bearer `+ localStorage.token }})
          .then(succes => {
            this.$emit('dialog', succes.data.body, 5000);
            this.CloseDialog('ShowEditUser');
          })
          .catch(error => {
            if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
            this.$emit('dialog', error.response.data.body, 5000);
          })
        }
      },
      GetUsers() {
        axios.post(this.$api+'/user/get', {}, {
          headers: { Authorization: `Bearer `+ localStorage.token }
        })
        .then(succes => {
          this.Users = succes.data;
        })
        .catch(error => {
          if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
          console.error(error);
        })
      }
    }
  }
</script>