<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Gebruiker lijst</span>
        </v-card-title>
        <v-card-text>
          <v-container>

          <v-list three-line>
          <template v-for="(user, index) in users">
            <v-subheader
              v-if="user.header"
              :key="user.header"
              v-text="user.header"
            ></v-subheader>

            <v-divider
              v-else-if="user.divider"
              :key="index"
              :inset="user.inset"
            ></v-divider>

            <v-list-item
              v-else
              :key="user.id"
            >
              <v-list-item-avatar>
                <v-img :src="user.avatar"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="user.username"></v-list-item-title>
                <v-list-item-subtitle>Email: {{user.email}} <br> Role: {{user.role}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
         
          </v-container>
          <small>Alle gebruikers in het systeem</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="CloseDialog('ShowGetUser')"
          >
            terug
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from 'axios';

  export default {
    data() {
      return {
          dialog: true,
          users: [],
      }
    },
    methods: {
      CloseDialog(dialog) {
        this.$emit('CloseDialog', dialog)
      },
      GetUsers() {
        axios.post(this.$api+'/user/get', {}, {
          headers: { Authorization: `Bearer `+ localStorage.token }
        })
        .then(succes => {

          for(var i=0; i < succes.data.length; i++) {
            this.users.push({
              id: succes.data[i].id,
              avatar:'https://picsum.photos/200',
              username: succes.data[i].first_name + " " + succes.data[i].last_name,
              email: succes.data[i].email,
              role: succes.data[i].role
            },)
            
          }
        })
        .catch(error => {
          if(error.response.status == 401) { location.reload(); } // If token is expired, reload the page.
          console.error(error);
        })
      }
    },
    mounted() {
      this.GetUsers();
    }
  }
</script>

<style scoped>

</style>